
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import PeopleFind from "../../../components/People/people-find.vue";
import Campana from "../../../store/entities/OZONE/campana";
import ModalStock from "../envios/modalStock.vue";
import { PaginationNavPlugin } from "bootstrap-vue";
import HistorialEnvios from "./history.vue";
import Exportacion from"../../../store/entities/OZONE/exportacion"
import Envio from "../../../store/entities/OZONE/envio";
import EditEnvio from "./editarEnvio.vue"

class PageEnvioRequest extends PageRequest {
  SearchName: string;
  SearchEmail: string;
  Mailing: string;
  SearchCP: string;
  SearchAddress: string;
  referencia: string;
  fechaInicio: Date;
  fechaDevolucion: Date;
  fechaDevolucionHasta: Date;
  fechaCreacionHasta: Date;
  fechaEnTramiteDesde: Date;
  fechaEnTramiteHasta: Date;
  fechaFin: Date;
  campana: Campana;
  enviado: Boolean;
  pendiente: Boolean;
  devuelto: Boolean;
  enTramite: Boolean;
  exportacion: Exportacion
}

@Component({
  components: {
    PeopleFind,
    ModalStock,
    HistorialEnvios,
    EditEnvio
  },
})

export default class Envios extends AbpBase {
  sort: string = "id desc";
  pagerequest: PageEnvioRequest = new PageEnvioRequest();
  innerpagerequest: PageEnvioRequest = new PageEnvioRequest();
  exportacion: Exportacion 
  pagination: any = {};
  innerpagination: any = {};
  campana: Campana = null;
  currentCampana: Campana = null;
  currentSearch: { key: string; value: string; label: string }[] = [];
  findModalShow: boolean = false;
  vista: string = "group";
  editModalShow: boolean = false;
  envioInfo: Envio = null;
  isReenvio: boolean = false;

  stockModalShow: boolean = false;
  entityId: number = 0;

  historialModalShow: boolean = false;
  selectedEnvio: number = null;
  anon: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "SearchName",
      value: "",
    },
    {
      fieldName: "Email",
      fieldType: "text",
      fieldBind: "SearchEmail",
      value: "",
    },
    {
      fieldName: "Dirección",
      fieldType: "text",
      fieldBind: "SearchAddress",
      value: "",
    },
    {
      fieldName: "Código Postal",
      fieldType: "text",
      fieldBind: "SearchCP",
      value: "",
    },
    {
      fieldName: "Referencia",
      fieldType: "text",
      fieldBind: "referencia",
      value: "",
    },
    {
      fieldName: "Campaña",
      fieldType: "custom",
      custom: "select-campana",
      fieldBind: "campana",
      value: this.campana,
      filter: {
        tipoCampanaId: 7,
      },
    },
    {
      fieldName: "Fecha de Creación",
      fieldType: "date",
      fieldBind: "fechaInicio",
      value: ""
    },
    {
      fieldName: "Fecha de Creación hasta",
      fieldType: "date",
      fieldBind: "fechaCreacionHasta",
      value: ""
    },
    {
      fieldName: "Fecha de devolución desde el",
      fieldType: "date",
      fieldBind: "fechaDevolucion",
      value: ""
    },
    {
      fieldName: "Fecha de devolución hasta el",
      fieldType: "date",
      fieldBind: "fechaDevolucionHasta",
      value: ""
    },
    {
      fieldName: "Fecha en Trámite desde",
      fieldType: "date",
      fieldBind: "fechaEnTramiteDesde",
      value: ""
    },
    {
      fieldName: "Fecha en Trámite hasta",
      fieldType: "date",
      fieldBind: "fechaEnTramiteHasta",
      value: ""
    },
    {
      fieldName: "Pendiente",
      fieldType: "checkbox",
      fieldBind: "pendiente",
      value: false,
    },
    {
      fieldName: "Enviado",
      fieldType: "checkbox",
      fieldBind: "enviado",
      value: false,
    },
    {
      fieldName: "Devuelto",
      fieldType: "checkbox",
      fieldBind: "devuelto",
      value: false,
    },
    {
      fieldName: "En Trámite",
      fieldType: "checkbox",
      fieldBind: "enTramite",
      value: false,
    },
  ];

  expandedCampaigns = [];
  $store: any;

  get list() {
    return this.$store.state.envio.list;
  }
  get listGroup() {
    return this.$store.state.envio.listGroup;
  }
  get innerData() {
    return this.$store.state.envio.list;
  }
  get loading() {
    return this.$store.state.envio.loading;
  }
  get loadingGroup() {
    return this.$store.state.envio.loadingGroup;
  }
  get pageSize() {
    return this.$store.state.envio.pageSize;
  }
  get totalCount() {
    return this.$store.state.envio.totalCount;
  }
  get totalCountGroup() {
    return this.$store.state.envio.totalCountGroup;
  }
  get currentPage() {
    return this.$store.state.envio.currentPage;
  }
  get currentPageGroup() {
    return this.$store.state.envio.currentPageGroup;
  }

  async created() {
    if (this.$route.query.exp) {
      this.vista = "ind"
      var exportacion = new Exportacion();
      exportacion.id = parseInt(this.$route.query.exp as string);
      this.innerpagerequest.exportacion = exportacion;
      this.exportacion = exportacion;
      this.getInnerPage(null);
    }
   else{
     this.getpage()
   }
  }

  async changeView() {
    if (this.vista == "group") {
      await this.getpage();
    } else {
      await this.getInnerPage(null);
    }
  }

  pageChange(page: number) {
    this.$store.commit("envio/setCurrentPage", page);
    this.getpage();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("envio/setPageSize", pagesize);
    this.getpage();
  }

  async getSearch(model) {
    this.currentSearch.length = 0;

    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
        this.innerpagerequest[element.fieldBind] = element.value;
        console.log("total count: "+this.totalCount)
        if(this.totalCount>0){
          this.currentSearch.push({
          label: element.fieldName,
          key: element.fieldBind,
          value:
            element.fieldType === "custom"
              ? element.value[element.display]
              : element.fieldType == "checkbox"
              ? ""
              : element.value,
        });
        }
       
      } else {
        delete this.pagerequest[element.fieldBind];
        delete this.innerpagerequest[element.fieldBind];
      }
    });

    this.$store.commit("envio/setCurrentPage", 1);
    this.$store.commit("envio/setCurrentPageGroup", 1);
    if (this.vista == "group") {
      await this.getpage();
    } else {
      await this.getInnerPage(
        this.currentCampana ? this.currentCampana.id : null
      );
    }
    this.findModalShow = false;
  }
//
  async deleteFilter(ixFilter) {
    var filter = this.currentSearch[ixFilter];
    delete this.pagerequest[filter.key];
    (
      this.$refs.peoplefind as Vue & { resetField: (key: string) => void }
    ).resetField(filter.key);
    await this.getpage();
  }

  async reset() {
    (this.$refs.peoplefind as Vue & { reset: () => void }).reset();
    this.currentSearch.length = 0;
  }

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  async getpage() {
    this.pagerequest.maxResultCount = this.pagination.pageSize;
    this.pagerequest.skipCount = (this.currentPageGroup - 1) * this.pageSize;
    this.pagerequest.sorting = this.sort;

    await this.$store.dispatch({
      type: "envio/getAllGroup",
      data: this.pagerequest,
    });
    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.envio.totalCountGroup;
    pagination.showTotal = () =>
      `${this.$store.state.envio.totalCountGroup} campañas de envios.`;
    pagination.pageSize = this.$store.state.envio.pageSize;
    pagination.current = this.$store.state.envio.currentPageGroup;
    this.pagination = pagination;
  }

  async getInnerPage(campanaId) {
    this.innerpagerequest.maxResultCount = this.innerpagination.pageSize;
    this.innerpagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    this.innerpagerequest.sorting = this.sort;
    if (campanaId) {
      let campana = new Campana();
      campana.id = campanaId;
      this.innerpagerequest.campana = campana;
      this.currentCampana = campanaId;
    }

    await this.$store.dispatch({
      type: "envio/getAll",
      data: this.innerpagerequest,
    });

    const pagination = { ...this.innerpagination };
    pagination.total = this.$store.state.envio.totalCount;
    pagination.showTotal = () =>
      `${this.$store.state.envio.totalCount} envios.`;
    pagination.pageSize = this.$store.state.envio.pageSize;
    pagination.current = this.$store.state.envio.currentPage;
    this.innerpagination = pagination;
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("envio/setCurrentPageGroup", pagination.current);
    this.getpage();

    await this.getpage();
  };

  handleInnerTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("envio/setCurrentPage", pagination.current);
    await this.getInnerPage(
      this.currentCampana ? this.currentCampana.id : null
    );
  };

  goToParticipacion(participacionId) {
    //this.$store.commit("participacion/edit", { item });
    this.$router.push({ name: "editparticipacion", params: { id: participacionId } });
  }

  hideWord(w) {
    if(w == null){
      return "";
    }

    if(!this.anon){
      return w;
    }
    if (w.length < 3) return w;
    return w.substring(0, 2) + "*".repeat(w.length - 2);
  }

  expandTable = async (expanded, record) => {
    let campaignId = record.id;
    this.$store.commit("envio/clearData");
    if (expanded) {
      //this.expandedCampaigns = [record.id]
      this.expandedCampaigns.pop();
      this.expandedCampaigns.push(campaignId);
      await this.getInnerPage(campaignId);
    } 
    else {
      this.expandedCampaigns.pop();
      this.currentCampana = null;
    }
  };

  showStock(item, index, button) {
    this.entityId = item.id;
    this.stockModalShow = true;
  }

  showHistory(id) {
    this.selectedEnvio = id;
    this.historialModalShow = true;
  }

  reenviarOActualizar(envio, isReenvio: boolean){
    this.envioInfo = envio;
    this.isReenvio = isReenvio;
    this.editModalShow = true;
  }

  reenvioOk(reenvio){
    if(this.isReenvio){
      if(reenvio && reenvio.id > 0){
        this.$store.commit("envio/addNewEnvio", reenvio);
        this.$message.success("Se ha realizado el reenvio correctamente", 5);
      }
      else{
        this.$message.error("No se ha podido realizar el reenvio", 5)
      }
    }
    else{
      this.$message.success("Se ha actualizado el envío correctamente", 5);
      this.getInnerPage(this.currentCampana ? this.currentCampana.id : null);
    }
  }

  async marcarUrgente(envio){
      this.$confirm({
        title:'¿Desea marcar este envío como urgente?',
        content: "Al marcar el envío como urgente, éste saldrá primero al exportar el fichero de envios.Esta acción no se puede deshacer.",
        okText: 'Sí',
        cancelText: 'No',
        icon: 'warning',
        onOk: async () => {
          envio.urgente = true;
          await this.$store.dispatch({
            type: "envio/marcarEnvioUrgente",
            data: envio
          }).then(value => {
            if(value){
              this.$message.success("Envio marcado como urgente correctamente.", 5);
            }
            else{
              this.$message.error("No se ha podido marcar como urgente.", 5)
            }
          });
        }
      });
  }

  stateColorScheme(state: number) {
    switch (state) {
      case 1:
        return "ant-tag-primary";
      case 5:
        return "ant-tag-secondary";
      case 2:
        return "ant-tag-success";
      case 3:
        return "ant-tag-danger";
      case 6:
        return "ant-tag-warning";
    }
  }

  columns = [
    {
      title: this.L("Campaña"),
      scopedSlots: { customRender: "func" },
      key: "cmp",
    },
    {
      title: this.L("Estimado"),
      dataIndex: "campana.participacionesEstimadas",
    },
    {
      title: this.L("Estado Envíos"),
      scopedSlots: { customRender: "status" },
      key: "este",
    },
    {
      title: this.L("Total Envíos"),
      dataIndex: "total",
    },
    {
      scopedSlots: { customRender: "editBtn" },
      width: 10,
      title: "Mirar Stock",
    },
  ];

  innerColumns = [
    {
      title: this.L("Nombre"),
      scopedSlots: { customRender: "func" },
      key: "inc",
    },
    {
      title: this.L("Direccion"),
      scopedSlots: { customRender: "dir" },
      key: "dir",
    },
    {
      title: this.L("Campaña"),
      scopedSlots: { customRender: "camp" },
    },
    {
      title: this.L("Estado"),
      scopedSlots: { customRender: "status" },
    },
    // {
    //   scopedSlots: { customRender: "historial" },
    //   width: 10,
    // },
    {
      scopedSlots: { customRender: "options" },
      width: 5,
    }
  ];
}
